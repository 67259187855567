body {
  margin: 0;
  font-family: "soma", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ant-btn-primary {
  border-color: #018b2b !important;
  background: #018b2b !important;
}

.ant-btn-dangerous.ant-btn-primary {
  border-color: #ff4d4f !important;
  background: #ff4d4f !important;
  color: white;
}

.ant-btn-dangerous.ant-btn-primary:disabled, .ant-btn-primary:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
  text-shadow: none !important;
  box-shadow: none !important;
}
