@import "antd/dist/antd.css";
@import "./assets/styles/bootstrap.min.css";
@import "./assets/styles/fonts.css";
@import "primereact/resources/themes/lara-light-indigo/theme.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";

.font-fm-light-italic {
  font-family: "soma-light-italic" !important;
}
.font-fm-light {
  font-family: "soma-light" !important;
}
.font-fm-medium {
  font-family: "soma-medium" !important;
}

.font-fm-medium-italic {
  font-family: "soma-medium-italic" !important;
}

.font-fm-regular {
  font-family: "soma-regular" !important;
}

.font-fm-semi-bold {
  font-family: "soma-semi-bold" !important;
}

.font-fm-bold {
  font-family: "soma-bold" !important;
}
.font-fm-bold-italic {
  font-family: "soma-bold-italic" !important;
}
.font-fm-ex-bold {
  font-family: "soma-ex-bold" !important;
}

.font-fm-ex-bold-italic {
  font-family: "soma-ex-bold-italic" !important;
}

.loader-overlay {
  opacity: 0.5;
  filter: alpha(opacity=20);
  background-color: #000;
  width: 100%;
  height: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  position: fixed;
}
