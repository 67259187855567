@font-face {
    font-family: 'soma-light-italic';
    src: url("../fonts/somar/ArbFONTS-Somar-ExtraLightItalic.otf") format("opentype");
    font-weight: lighter;
    font-style: italic;
}
@font-face {
    font-family: 'soma-light';
    src: url("../fonts/somar/ArbFONTS-Somar-Light.otf") format("opentype");
    font-weight: 300;
}
@font-face {
    font-family: 'soma-medium';
    src: url("../fonts/somar/ArbFONTS-Somar-Medium.otf") format("opentype");
    font-weight: normal;
}
@font-face {
    font-family: 'soma-medium-italic';
    src: url("../fonts/somar/ArbFONTS-Somar-MediumItalic.otf") format("opentype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'soma-regular';
    src: url("../fonts/somar/ArbFONTS-Somar-Regular.otf") format("opentype");
    font-weight: normal;
}

@font-face {
    font-family: 'soma-semi-bold';
    src: url("../fonts/somar/ArbFONTS-Somar-SemiBold.otf") format("opentype");
    font-weight: 600;
}

@font-face {
    font-family: 'soma-bold';
    src: url("../fonts/somar/ArbFONTS-Somar-Bold.otf") format("opentype");
    font-weight: bold;
}
@font-face {
    font-family: 'soma-bold-italic';
    src: url("../fonts/somar/ArbFONTS-Somar-BoldItalic.otf") format("opentype");
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'soma-ex-bold';
    src: url("../fonts/somar/ArbFONTS-Somar-ExtraBold.otf") format("opentype");
    font-weight: bolder;
}

@font-face {
    font-family: 'soma-ex-bold-italic';
    src: url("../fonts/somar/ArbFONTS-Somar-ExtraBoldItalic.otf") format("opentype");
    font-weight: bolder;
    font-style: italic;
}